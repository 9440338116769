import { roles } from '@/enums'

export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()
  const schoolId = to.params.schoolId
  const isTeacher = user.value?.user_metadata.schoolRoles?.find(
    (schoolRole: SchoolRole) =>
      schoolRole.schoolId === schoolId && schoolRole.role === roles.TEACHER,
  )
  if (!isTeacher) {
    return abortNavigation()
  }
})
